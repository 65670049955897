@import 'components/Layout/mixins';
@import 'components/Main/lib/mixins';
@import 'components/Main/Button/mixins';

.container {
  --reducer-vertical-padding: 0;
  --reducer-horizontal-padding: 16px;

  min-width: 358px;
  position: relative;
}

.footer,
.navigation {
  @include reducerWidth(1280px);
}

.navigation {
  position: absolute;
  top: 0;
  width: 100%;
}

.navigationPosition-sticky {
  position: fixed;
}

.content {
  @include reducerWidth(1200px);
}

.navigationHidden {
  opacity: 0;
  visibility: hidden;
}
