@import 'styles/layout';
@import 'styles/core/typography';

.badge {
  bottom: 24px;
  left: 32px;
  position: absolute;
  right: 32px;
  top: 24px;

  @include media-max-target('tablet-small') {
    bottom: 16px;
    left: 16px;
    right: 16px;
    top: 16px;
  }

  .inner {
    @include textS;
    background-color: var(--joompro-core-color-red);
    border-radius: 12px;
    color: var(--joompro-core-color-white);
    font-family: var(--joompro-core-font-joompro);
    padding: 8px 8px;
    position: absolute;

    &.left-top {
      left: 0;
      top: 0;
    }

    &.left-bottom {
      bottom: 0;
      left: 0;
    }

    &.right-top {
      right: 0;
      top: 0;
    }

    &.right-bottom {
      bottom: 0;
      right: 0;
    }
  }
}
