@import 'styles/core/button';
@import 'styles/layout';
@import 'components/Logo/mixins';

.footer {
  --footer-text-color: var(--joompro-core-color-text-main-invert);
  --joompro-main-logo-color: var(--joompro-core-color-text-main-invert);

  display: grid;
  grid-gap: 24px;
  padding: 60px 0;

  @include media-max-target('tablet-small') {
    padding: 40px 0;
  }
}

.logo {
  @include logo-large-mode;
}

.columns {
  display: flex;
  flex-direction: var(--footer-columns-direction, row);
  flex-wrap: var(--footer-columns-wrap, wrap);
  gap: 24px;
  justify-content: space-between;

  @include media-max-target('tablet-small') {
    flex-direction: var(--footer-columns-direction, column);
    flex-wrap: var(--footer-columns-wrap, nowrap);
  }
}

.doubleAddress {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 400px;
}

.chinaAddress {
  color: var(--footer-text-color);
  display: flex;
  flex-direction: column;
  gap: 12px;
}
