@import 'styles/core/typography';

.container {
  color: var(--footer-text-color);
  max-width: 250px;
}

.title {
  @include textM;

  display: inline-block;
  margin-bottom: 12px;
}

.address {
  @include textM;
}

.flag {
  margin-right: 6px;
  vertical-align: 1px;
  width: 11px;
}

.icon {
  background-color: var(--footer-text-color);
  display: inline-block;
  height: 16px;
  margin-bottom: 12px;
  margin-right: 4px;
  mask: url('./officePin.svg') no-repeat center / contain;
  vertical-align: bottom;
  width: 16px;
}
