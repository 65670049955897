@import 'styles/layout';
@import 'styles/hideScrollbars';
@import 'styles/core/typography';
@import 'components/Layout/mixins';
@import 'components/Main/lib/mixins';

.container {
  @include reducerWidth(1280px);
  background: var(--joompro-core-color-black-1100);
  color: var(--joompro-core-color-text-main-invert);
  padding-top: 50px;

  @include mobile {
    padding-top: 40px;
  }
}

.title {
  @include promoTextM;
  margin: 0;
}

.tags {
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include mobile {
    @include hideScrollbars;
    @include stretchToViewport;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.tag {
  @include promoTextM;
  background: var(--joompro-core-color-primary-hover-invert);
  border-radius: 100px;
  display: inline-block;
  margin: 16px 12px 0 0;
  padding: 7px 16px 9px;

  &:last-child {
    margin-right: 0;
  }

  &[role='button'] {
    cursor: pointer;
  }
}
