@import 'styles/core/typography';
@import 'styles/core/button';
@import 'styles/core/hover';
@import 'styles/layout';

.offices {
  color: var(--footer-text-color);
  font-size: 14px;
}

.title {
  @include subTitleM;
  margin-bottom: 12px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -12px;
}

.office {
  @include textM;
  flex: 1;
  margin-bottom: 12px;
}

.office + .office {
  border-left: 1px solid var(--joompro-core-color-gray-1000);
  margin-left: 12px;
  padding-left: 12px;
}

.flag {
  margin-right: 10px;
  vertical-align: 1px;
  width: 11px;
}

.hideButton {
  @include resetButton;
  @include lineM;

  color: var(--footer-text-color);
  cursor: pointer;
  display: none;
  margin-top: 24px;

  @include real-hover {
    text-decoration: none;
  }
}

@include media-max-target('tablet-small') {
  .list {
    display: block;
  }

  .office + .office {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  .hidden {
    display: none;
  }

  .hideButton {
    display: block;
  }
}

@include media-targets('tablet-medium') {
  .office {
    flex: 30%;

    &:nth-child(4) {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
}
