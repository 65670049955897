@import 'styles/core/typography';
@import 'styles/core/button';

.title {
  @include textM;
  color: var(--footer-text-color);
  margin-bottom: 12px;
}

.strongTitle {
  @include subTitleM;
}

.button {
  @include resetButton;
  @include lineM;
  color: var(--footer-text-color);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
