@import 'styles/text';
@import 'styles/core/typography';
@import 'styles/layout';

.root {
  list-style: none;

  &:hover .image {
    transform: scale(1.1);

    @include media-max-target('tablet-medium') {
      transform: none;
    }
  }
}

.image {
  transition: 0.3s transform;
}

.title {
  @include promoSubTitleL;
  @include lineClamp(2, 24px);

  display: block;
  margin-top: 20px;

  @include media-max-target('tablet-medium') {
    margin-top: 16px;
    @include promoSubTitleM;
  }
}

.titleHero {
  @include promoTitleAlternativeH5;

  display: block;
  margin-top: 20px;

  @include media-max-target('tablet-medium') {
    margin-top: 16px;
    @include promoSubTitleM;
  }
}

.link {
  text-decoration: none;
}

.description {
  @include promoTextL;
  @include lineClamp(2, 24px);
  color: var(--joompro-core-color-text-secondary);
  margin-top: 8px;

  min-height: 48px; /* to fill 2 lines, should copy text line-height */

  @include media-max-target('tablet-medium') {
    @include promoTextM;
    @include lineClamp(2, 20px);

    min-height: 40px; /* to fill 2 lines, should copy text line-height */
  }
}

.footer {
  margin-top: 16px;

  @include media-max-target('tablet-medium') {
    margin-top: 10px;
  }
}

.hero {
  grid-column-end: 3;
  grid-column-start: 1;

  @include media-max-target('tablet-small') {
    grid-column-end: initial;
    grid-column-start: initial;
  }
}
