@import 'components/Layout/mixins';
@import 'components/Footer/mixins';
@import 'components/Main/lib/mixins';

.container {
  @include reducerWidth(1280px);

  background: var(--joompro-core-color-black-1100);

  @include mobile {
    @include footerPadding(40px 0);
    @include footerColumnsDirection(column, nowrap);
    padding-top: 0;
  }
}
