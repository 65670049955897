@mixin logo-compact-mode() {
  --joompro-main-logo-height: 20px;
  --joompro-main-logo-widht: 118px;
}

@mixin logo-large-mode() {
  --joompro-main-logo-height: 32px;
  --joompro-main-logo-widht: 188px;
}

@mixin logo-color($color) {
  --joompro-main-logo-color: $color;
}
