@import 'styles/core/typography';

.companies {
  color: var(--footer-text-color);
}

.title {
  @include subTitleM;
  margin-bottom: 12px;
}

.list {
  column-gap: 16px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
}
