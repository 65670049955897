@import 'styles/core/button';

.root {
  @include resetButton;
  align-items: center;

  cursor: pointer;
  display: flex;
  gap: var(--button-toggle-gap, 4px);
}
