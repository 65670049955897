@import 'styles/core/typography';

.copyright {
  @include textM;
  color: var(--footer-text-color);
}

.year {
  display: inline-block;
  margin-right: 4px;
}
