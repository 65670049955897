@import 'styles/layout';
@import 'styles/core/typography';

.title {
  @include promoTitleH3;

  margin: 0;
  padding: 0;

  @include media-max-target('tablet-medium') {
    @include promoTitleH5;
    flex-basis: 100%;
  }
}

.prefix {
  color: var(--joompro-core-color-accent-default);
}
