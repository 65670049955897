@import 'styles/layout';
@import 'styles/core/typography';

.container {
  background: var(--joompro-core-color-white);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.navigationFullscreen {
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.content {
  @include promoAccentM;

  align-items: center;
  color: black;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 16px 0;

  @include media-max-target('tablet-small') {
    padding: 12px 0;
  }
}

.nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-max-target('tablet-medium') {
    display: none;
  }
}

.navGroup {
  align-items: center;
  display: flex;
  gap: 20px;
}

.mobileMenu {
  display: none;
}

.mobileMenuOpened {
  @include media-max-target('tablet-medium') {
    display: flex;
  }
}
