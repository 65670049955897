.phone {
  font-size: inherit;
  line-height: inherit;
}

.link {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  text-decoration: inherit;
}
