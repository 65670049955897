@import 'styles/core/button';
@import 'styles/legacy/colors';
@import 'styles/layout';

.layout {
  background-color: var(--joompro-core-color-accent-default);
  box-sizing: border-box;
  color: $color-white;
  font-size: 18px;
  line-height: 22px;
  padding: 16px 0;
  position: relative;
}

.wrap {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.content {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.message {
  background: url('./icons/promo-check.svg') no-repeat top left / 24px;
  font-weight: 200;
  padding-left: 40px;

  @include media-max-target('tablet-small') {
    background: url('./icons/promo-check.svg') no-repeat top left / 20px;
    padding-left: 36px;
  }
}

.promocode {
  font-weight: 400;
}

.closeButton {
  @include resetButton();

  background: currentColor;
  cursor: pointer;
  flex-shrink: 0;
  height: 22px;
  mask: url('~icons/cross.svg') no-repeat center / 16px;
  opacity: 0.6;
  transition: opacity 0.1s ease;
  width: 22px;

  &:hover,
  &:active {
    opacity: 1;
  }
}
