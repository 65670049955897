@import 'styles/core/button';
@import 'styles/core/typography';

.container {
  display: inline-block;
}

.button {
  @include resetButton;
  @include textM;

  color: var(--footer-text-color);
  cursor: pointer;
}

.icon {
  background-color: var(--footer-text-color);
  display: inline-block;
  height: 16px;
  vertical-align: bottom;
  width: 16px;
}

.globe {
  margin-right: 4px;
  mask: url('~icons/globe.svg') no-repeat center / contain;
}

.arrow {
  margin-left: 4px;
  mask: url('./chevron-down-edgy.svg') no-repeat center / contain;
}
