@import 'components/Main/lib/mixins';

.mobile {
  display: none;
}

@include mobile {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
