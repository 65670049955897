@import 'styles/layout';

.tablet {
  display: none;
  gap: 12px;
  @include media-max-target('tablet-medium') {
    display: flex;
  }
}

.desktop {
  @include media-max-target('tablet-medium') {
    display: none;
  }
}

.icon {
  font-size: 24px;
}
