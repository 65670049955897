.root {
  border-radius: 28px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%; /* 16/9 */
  }
}

.image {
  bottom: 0;
  /* need both absolute position and width/height for working hack */
  height: 100%;
  left: 0;
  object-fit: cover;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.loaded {
  opacity: 1;
}
