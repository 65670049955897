@import 'styles/layout';

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;

  @include media-max-target('tablet-medium') {
    flex-wrap: wrap;
  }
}
