.container {
  background: var(--joompro-core-color-gray-100);
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.icon {
  margin: auto;
}
