@mixin promoTitleH1 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 84px;
  font-weight: 400;
  line-height: 84px;
}

@mixin newPromoTitleH1 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 72px;
  font-weight: 400;
  line-height: 77px;
}

@mixin promoTitleH2 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 72px;
  font-weight: 400;
  line-height: 77px;
}

@mixin newPromoTitleH2 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 52px;
  font-weight: 400;
  line-height: 58px;
}

@mixin promoTitleH3 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 40px;
  font-weight: 400;
  line-height: 44px;
}

@mixin promoTitleH4 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

@mixin promoTitleH5 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
}

@mixin promoTitleH6 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

@mixin promoTitleAlternativeH3 {
  font-family: var(--joompro-core-font-signal);
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
}

@mixin promoTitleAlternativeH5 {
  font-family: var(--joompro-core-font-signal);
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
}

@mixin promoTitleAlternativeH6 {
  font-family: var(--joompro-core-font-signal);
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

@mixin promoSubTitleXL {
  font-family: var(--joompro-core-font-signal);
  font-size: 24px;
  font-weight: 200;
  line-height: 30px;
}

@mixin promoSubTitleL {
  font-family: var(--joompro-core-font-signal);
  font-size: 24px;
  font-weight: 200;
}

@mixin promoSubTitleM {
  font-family: var(--joompro-core-font-signal);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@mixin promoSubTitleS {
  font-family: var(--joompro-core-font-signal);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@mixin promoSubTitleAlternativeXL {
  font-family: var(--joompro-core-font-signal);
  font-size: 24px;
  font-weight: 200;
  line-height: 29px;
}

@mixin promoSubTitleAlternativeM {
  font-family: var(--joompro-core-font-joompro);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@mixin promoSubTitleAlternativeS {
  font-family: var(--joompro-core-font-joompro);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@mixin promoTextXL {
  font-family: var(--joompro-core-font-signal);
  font-size: 20px;
  font-weight: 200;
  line-height: 32px;
}

@mixin promoTextL {
  font-family: var(--joompro-core-font-signal);
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
}

@mixin promoTextM {
  font-family: var(--joompro-core-font-signal);
  font-size: 16px;
  font-weight: 200;
  line-height: 20px;
}

@mixin promoAccentXL {
  font-family: var(--joompro-core-font-signal);
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
}

@mixin promoAccentL {
  font-family: var(--joompro-core-font-signal);
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

@mixin promoAccentM {
  font-family: var(--joompro-core-font-signal);
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

@mixin promoLineXL {
  font-family: var(--joompro-core-font-joompro);
  font-size: 20px;
  font-weight: 200;
  line-height: 24px;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-offset: 2px;
}

@mixin promoLineL {
  @include promoTextL;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-offset: 2px;
}

@mixin promoLineM {
  @include promoTextM;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-offset: 2px;
}
