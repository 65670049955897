@import 'styles/layout';

.root {
  background: var(--joompro-core-color-white);
  box-sizing: border-box;
  padding-top: 180px;

  @include media-max-target('tablet-medium') {
    padding-top: 140px;
  }
}

.layout {
  display: grid;
  grid-column-gap: 60px;
  grid-row-gap: 48px;
  grid-template-columns: repeat(3, 1fr);
  margin: 0;
  margin-bottom: 80px;
  margin-top: 60px;
  padding: 0;

  @include media-max-target('tablet-medium') {
    grid-column-gap: 48px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 60px;
    margin-top: 40px;
  }

  @include media-max-target('tablet-small') {
    grid-template-columns: 1fr;
  }
}
