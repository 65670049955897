@import 'styles/legacy/colors';

/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$shadow:
  0 0 10px $color-black-alpha10,
  0 0 10px $color-black-alpha10;

.popover {
  display: block;
  position: absolute;

  &.withBackground {
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: $shadow;
  }
  &.rounded {
    border-radius: 12px;
  }
}

.arrow {
  height: 1px;
  position: absolute;
  width: 1px;

  &:before {
    background-color: $color-white;
    box-shadow: $shadow;
    content: '';
    display: block;
    height: 13px;
    left: -6px;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    transform-origin: center;
    width: 13px;
  }

  [data-popper-placement^='top'] & {
    bottom: 0;
  }

  [data-popper-placement^='bottom'] & {
    top: 0;
  }

  [data-popper-placement^='left'] & {
    right: 0;
  }

  [data-popper-placement^='right'] & {
    left: 0;
  }
}

.content {
  background-color: inherit;
  border-radius: inherit;
  display: inline-block; // inline-block needs to prevent margin collapsing
  position: relative;
}
