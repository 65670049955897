@import 'styles/core/typography';
@import 'styles/core/button';
@import 'styles/core/hover';
@import 'styles/layout';

.office {
  @include textM;
  flex: 1;
  margin-bottom: 12px;
  max-width: 250px;
}

.office.withDivider {
  border-left: 1px solid var(--joompro-core-color-gray-1000);
  padding-left: 12px;
}

.office + .office {
  border-left: 1px solid var(--joompro-core-color-gray-1000);
  margin-left: 12px;
  padding-left: 12px;
}

.flag {
  margin-right: 10px;
  vertical-align: 1px;
  width: 11px;
}

@include media-max-target('tablet-small') {
  .office + .office {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  .hidden {
    display: none;
  }
}

@include media-targets('tablet-medium') {
  .office {
    flex: 30%;

    &:nth-child(4) {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
}
