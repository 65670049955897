@import 'styles/core/typography';

.mainAddressPart {
  @include textM;
  color: var(--footer-text-color);

  & + & {
    margin-top: 12px;
  }
}

.mainAddressName {
  align-items: center;
  display: flex;
  gap: 4px;
}

.icon {
  background-color: var(--footer-text-color);
  display: inline-block;
  height: 16px;
  mask: url('./officePin.svg') no-repeat center / contain;
  vertical-align: bottom;
  width: 16px;
}
