@import 'styles/text';
@import 'styles/core/typography';

.root {
  @include promoTextM;

  align-items: center;
  color: var(--joompro-core-color-text-secondary);
  display: flex;
}

.delimeter {
  background: var(--joompro-core-color-text-secondary);
  height: 1em;
  margin: 2px 10px;
  width: 1px;
}
