@import 'styles/layout';

@mixin stretchToViewport {
  margin-left: calc(-1 * var(--reducer-horizontal-padding));
  margin-right: calc(-1 * var(--reducer-horizontal-padding));
  padding: 0 var(--reducer-horizontal-padding);
}

@mixin tablet() {
  @include media-max-target('tablet-large') {
    @content;
  }
}

@mixin tablet-medium() {
  @include media-max-target('tablet-medium') {
    @content;
  }
}

@mixin mobile() {
  @include media-max-target('tablet-small') {
    @content;
  }
}
