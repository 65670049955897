@import 'styles/core/typography';

.title {
  @include textM;
  color: var(--footer-text-color);
  margin-bottom: 12px;
}

.socials {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 12px;
}

.logo {
  background-color: var(--footer-text-color);
  display: inline-block;
  height: 24px;
  width: 24px;
}

.vc {
  mask: url('~icons/social-vc.svg') no-repeat center / contain;
}

.telegram {
  mask: url('~icons/social-telegram.svg') no-repeat center / contain;
}

.facebook {
  mask: url('~icons/social-fb.svg') no-repeat center / contain;
}

.linkedIn {
  mask: url('~icons/social-linked-in.svg') no-repeat center / contain;
}

.instagram {
  mask: url('~icons/social-instagram.svg') no-repeat center / contain;
}
