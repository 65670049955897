@import 'styles/core/button';
@import 'styles/legacy/colors';

.menuItem {
  @include resetButton;

  background-color: $color-white;
  box-sizing: border-box;
  color: $color-black-main;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  min-height: 32px;
  padding: 8px 16px;
  text-align: left;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: $color-black-alpha10;
  }
}
