@import 'styles/core/typography';
@import 'styles/layout';

.root {
  align-items: center;
  border: 2px solid var(--joompro-core-color-black-800);
  border-radius: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;

  @include media-max-target('tablet-medium') {
    border-radius: 32px;
    grid-column-end: 3;
    grid-column-start: 1;
    padding: 24px;
  }
  @include media-max-target('tablet-small') {
    grid-column-end: initial;
    grid-column-start: initial;
  }
}

.text {
  @include promoTitleH6;

  color: var(--joompro-core-color-text-title);
  display: block;
  margin-bottom: 12px;
  width: 100%;

  @include media-max-target('tablet-medium') {
    @include promoSubTitleM;
  }
}

.description {
  @include promoTextL;
  color: var(--joompro-core-color-text-main);

  display: block;
  margin-bottom: 24px;
  width: 100%;
}

.imageContainer {
  width: 244px;

  @include media-max-target('tablet-medium') {
    display: none;
  }

  @include media-max-target('tablet-small') {
    width: 263px;
  }
}

.image {
  height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%;
}

.form {
  display: flex;
  gap: 16px;
  width: 100%;
}

.input {
  flex-grow: 1;
}
