@import 'styles/core/typography';

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact {
  @include textM;
  color: var(--footer-text-color);
  display: block;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }
}

.link {
  @include lineM;
  color: var(--footer-text-color);
  display: block;

  &:hover {
    text-decoration: none;
  }
}
