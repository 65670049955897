@import 'styles/core/typography/promo';
@import 'styles/core/typography/main';
@import 'components/Main/lib/mixins';

.root {
  color: inherit;
  margin: 0;
}

.color-white {
  color: var(--joompro-core-color-white);
}

.color-black {
  color: var(--joompro-core-color-black-1100);
}

.color-red {
  color: var(--joompro-core-color-red);
}

.main {
  &.h1 {
    @include titleH1;
  }

  &.h2 {
    @include titleH2;
  }

  &.h2.alternative {
    @include titleAlternativeH2;
  }

  &.h3 {
    @include titleH3;
  }

  &.h3.alternative {
    @include titleAlternativeH3;
  }

  &.h5 {
    @include titleH5;
  }

  &.subtitle {
    &.size-m {
      @include subTitleM;
    }

    &.size-l {
      @include subTitleL;
    }
  }

  &.text {
    &.size-s {
      @include textS;
    }

    &.size-m {
      @include textM;
    }

    &.size-l {
      @include textL;
    }

    &.size-xl {
      @include textXL;
    }
  }

  &.accent {
    &.size-m {
      @include accentM;
    }

    &.size-s {
      @include accentS;
    }
  }

  &.line {
    &.size-l {
      @include lineL;
    }

    &.size-m {
      @include lineM;
    }

    &.size-s {
      @include lineS;
    }
  }
}

.promo {
  &.h1 {
    @include promoTitleH1;
  }

  &.h2 {
    @include promoTitleH2;
  }

  &.h3 {
    @include promoTitleH3;
  }

  &.h4 {
    @include promoTitleH4;
  }

  &.h5 {
    @include promoTitleH5;
  }

  &.h5.alternative {
    @include promoTitleAlternativeH5;
  }

  &.h6 {
    @include promoTitleH6;
  }

  &.h6.alternative {
    @include promoTitleAlternativeH6;
  }

  &.subtitle {
    &.size-xl {
      @include promoSubTitleXL;
    }

    &.size-m {
      @include promoSubTitleM;
    }

    &.size-l {
      @include promoSubTitleL;
    }

    &.size-s {
      @include promoSubTitleS;
    }

    &.alternative.size-m {
      @include promoSubTitleAlternativeM;
    }

    &.alternative.size-s {
      @include promoSubTitleAlternativeS;
    }
  }

  &.text {
    &.size-m {
      @include promoTextM;
    }

    &.size-l {
      @include promoTextL;
    }
  }

  &.accent {
    &.size-xl {
      @include promoAccentXL;
    }

    &.size-l {
      @include promoAccentL;
    }

    &.size-m {
      @include promoAccentM;
    }
  }

  &.line {
    &.size-xl {
      @include promoLineXL;
    }

    &.size-l {
      @include promoLineL;
    }

    &.size-m {
      @include promoLineM;
    }
  }
}

@include mobile {
  .promo {
    &.h1 {
      @include promoTitleH5;
    }

    &.h2 {
      @include promoTitleH5;
    }

    &.h4,
    &.h3 {
      @include promoTitleH6;
    }

    &.subtitle {
      &.size-l {
        @include promoTextM;
      }
    }

    &.text {
      &.size-l {
        @include promoTextM;
      }
    }

    &.accent {
      &.size-xl {
        @include promoAccentM;
      }

      &.size-l {
        @include promoAccentM;
      }
    }
  }
}
