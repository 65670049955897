@import 'styles/core/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text {
  @include promoAccentM;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.subscribe {
  bottom: 16px;
  left: var(--reducer-horizontal-padding);
  position: absolute;
  right: var(--reducer-horizontal-padding);
}
